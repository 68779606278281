import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

type HLSPlayerComponentProps = {
    name: string;
    endpoint: string;
};

const HLSPlayerComponent = ({ name, endpoint }: HLSPlayerComponentProps) => {
    const url = `${window.location.origin}/cameras/stream${endpoint}`;
    const remoteVideoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!remoteVideoRef.current) return;

        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(url);
            hls.attachMedia(remoteVideoRef.current);
        } else if (
            remoteVideoRef.current.canPlayType('application/vnd.apple.mpegurl')
        ) {
            remoteVideoRef.current.src = url;
        }
    }, [url]);

    return (
        <div className="col-span-1 relative">
            <p className="absolute font-bold bg-white p-1 m-2">{name}</p>
            <video ref={remoteVideoRef} autoPlay muted />
        </div>
    );
};
export default HLSPlayerComponent;
